// Plugins //
import "plugins/jquery.js";
import "plugins/popper.js";
import "plugins/bootstrap.js";
import "plugins/waves.js";
import "plugins/slick.js";
import "plugins/datatables.js";
import "plugins/select.js";
import "plugins/swipe.js";
import "plugins/luxon.js";

// Partials //
import "_homepage.js";
import "_mobile-menu.js";
import "_places-table.js";
import "_links-table.js";
import "_accordion.js";